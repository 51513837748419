<template>
  <div class="random-page">
    <div class="herosection">
      <div class="filigrane-color" />
      <img
        :src="require('@/assets/images/tourismeBenin/musee.jpg')"
        alt=""
        class="herosection-back-image"
      />
      <h1 style="color: #fff; text-transform: uppercase">
        {{ $t("home.professionnalAccess") }}
      </h1>
      <p class="my-2">
        {{ $t("home.professionnalAccessDescription") }}
      </p>
      <div class="btns-actions">
        <b-button href="/operateurs/create" class="m-0" variant="warning">{{
          $t("home.access.register")
        }}</b-button>
        <b-button class="m-0" variant="light" @click="login_sso()">
          {{ $t("home.access.loginSSO") }}
        </b-button>
        <router-link to="/login">
          <b-button class="m-0" variant="outline-light">{{
            $t("home.access.login")
          }}</b-button>
        </router-link>
        <!-- <b-button href="/otp-register" class="m-0" variant="outline-light"
          >Connexion OTP</b-button
        > -->
      </div>
    </div>
    <demandes-section v-if="isAuthenticated" />

    <!-- <div class="random-page-wrapper">
      <b-card>
        <h2 class="mb-2">Les services dématérialisés</h2>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import { BButton, BCard } from "bootstrap-vue";
import DemandesSection from "@/components/HomeComponents/DemandesSection.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BButton,
    BCard,
    DemandesSection,
  },
  computed: {
    ...mapState("auth", {
      isAuthenticated: "isAuthenticated",
    }),
  },
  data() {
    return {
      ssoUrlCallback: "http://137.255.9.61:27800/api/login/sso",
    };
  },
  methods: {
    login_sso() {
      window.location.href = `https://pprodofficial.service-public.bj/official/login?client_id=mainfo-mtca&redirect_uri=${this.ssoUrlCallback}&response_type=code&scope=openid&authError=true`;
    },
  },
};
</script>

<style scoped>
.random-page {
}
.random-page-wrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding: 2em;
}
.herosection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;

  padding: 6em 2em;
  /* height: 323px; */
}
.herosection h1 {
  font-size: 27px;
}
.herosection-back-image {
  position: absolute;
  object-fit: cover;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
}
.herosection .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}
.btns-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
::v-deep .btns-actions button {
  width: 100%;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .herosection h1 {
    font-size: 42px;
  }
  .btns-actions {
    flex-direction: row;
  }
  ::v-deep .btns-actions button {
    width: auto;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
</style>
