<template>
  <div id="make-demande" class="demandes-box">
    <div class="demandes-box-wrapper">
      <div class="d-flex flex-column">
        <h1 class="mb-1">{{ $t('home.possibleDemandesTitle') }}</h1>
        <div
          v-if="showAll || isAdminSet"
          class="d-flex align-items-center mb-1"
        >
          <div class="mr-1 ml-auto labelWide">{{ $t('home.filterBy') }}</div>

          <div>
            <div style="margin-bottom: 3px" class="mr-1 labelBase">
             {{ $t('home.filterBy') }} 
            </div>
            <v-select
              style="min-width: 205px"
              v-model="sectorSelected"
              :get-option-label="option => $t(option.title)"
              :options="sectorOptions"
            />
          </div>
        </div>
      </div>
      <div>
        <b v-show="sectorSelected.value === 'Tout'">{{ $t('all_sectors') }}</b>
        <b v-show="sectorSelected.value === 'UEGT'">{{ $t('guide_tourist') }}</b>
        <b v-show="sectorSelected.value === 'UEAV'">{{ $t('travel_agency') }}</b>
        <b v-show="sectorSelected.value === 'UEH'"> {{$t('establishment_hotel')}} </b>
        <b v-show="sectorSelected.value === 'UER'">{{ $t('establishment_restoration')}}</b>
        <div class="demandes-list">
          <a
            :href="demande.demande_link"
            target="_blank"
            v-for="(demande, index) in demandesFiltered"
            :key="index"
          >
            <b-card class="demande-card m-0">
              <!-- <b-avatar size="4em"> -->
              <div
                style="
                  gap: 0.2em;
                  display: grid;
                  grid-template-columns: 37px 1fr;
                "
              >
                <h2>
                  <feather-icon
                    :icon="demande.featherIcon || 'FileIcon'"
                    size="20"
                    class="d-inline"
                  />
                </h2>

                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  stroke="#706d7d"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text"
                >
                  <path
                    d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                  />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1="16" y1="13" x2="8" y2="13" />
                  <line x1="16" y1="17" x2="8" y2="17" />
                  <polyline points="10 9 9 9 8 9" />
                </svg> -->
                <!-- </b-avatar -->
                <h4
                  v-b-tooltip.hover
                  :title="$t(demande.title)"
                  class="truncator-two"
                >
                  {{ $t(demande.title) }}
                </h4>
              </div>
              <p style="color: #4b4b4b" class="truncator-three m-0 text-dark">
                {{ demande.description }}
              </p>
            </b-card>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BAvatar,
  VBTooltip,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import utilsService from '@/services/utils/utils.service';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    BCard,
    BAvatar,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      demandes: [],
      demandesSpecifiques: [],
      sectorSelected: {
        title: "all_sectors",
        value: 'Tout',
      },

      sectorOptions: [
        { title: 'all_sectors', value: 'Tout' },
        { title: "sectorsList.hebergement", value: 'UEH' },
        { title: 'sectors_tourism.tourism_agence_label', value: 'UEAV' },
        { title: 'sectors_tourism.tourism_guide_label', value: 'UEGT' },
        { title: 'sectorsList.restaurant', value: 'UER' },
      ],
      isAdminSet: false,
    };
  },
  computed: {
    demandesFiltered() {
      if (this.sectorSelected.value === 'Tout') return this.demandes;
      return this.demandes.filter(
        (d) => d.sector === this.sectorSelected.value
      );
    },
  },
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  // isAuthenticated && isInvestisor
  mounted() {
    const { listFormulaires } = utilsService.currentUserUtils();
    if (this.showAll) {
      this.demandes = listFormulaires;
    } else {
      const { isOperator, isAgentInterne, isAgentExterne, isAdmin } =
        utilsService.currentUserUtils();
      this.isOperator = isOperator;
      if (isOperator) {
        let title = '';
        if (isOperator.alias === 'UEH')
          title = "Établissement d'hébergement touristique";
        if (isOperator.alias === 'UEAV') title = 'Agence de voyage';
        if (isOperator.alias === 'UEGT') title = 'Guide de tourisme';
        if (isOperator.alias === 'UER') title = 'Etablissement de restauration';
        this.sectorSelected = { title, value: isOperator.alias };
        const { listFormulairesBySector } = utilsService.currentUserUtils();
        this.demandes = listFormulairesBySector;
      } else if (isAgentInterne || isAgentExterne || isAdmin) {
        this.sectorSelected = { title: 'all_sectors', value: 'Tout' };
        this.demandes = listFormulaires;
        this.isAdminSet = true;
      } else {
      }
    }
    // this.demandes = utilsService.formulaires;
  },
};
</script>

<style scoped>
.demandes-box {
  background-color: #f8f8f8;
}

.demandes-box-wrapper {
  padding: 3em 3em 4em 3em;
  max-width: 1200px;
  margin: 0 auto;
}

.demandes-list {
  display: grid;
  gap: 2em;
  margin-top: 1em;
}

.demande-card {
  outline: 2px solid rgba(255, 255, 255, 0);
  height: 100%;
  /* user-select: none;
  cursor: pointer; */
}

.demande-card:hover {
  outline: 7px solid rgba(15, 95, 48, 0.13);
}

.truncator-two {
}

.truncator-three {
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Show an ellipsis (...) when text overflows */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to display before truncation */
  -webkit-box-orient: vertical;
  max-height: 4.8em;
  /* (line height) x (number of lines) */
}

.labelWide {
  display: none;
}

::v-deep .vs__selected-options {
  overflow: hidden;
  white-space: nowrap;
  max-height: 30px;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .labelWide {
    display: block;
  }

  .labelBase {
    display: none;
  }
}

@media (min-width: 700px) {
  .truncator-two {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 43px;
  }

  .demandes-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1110px) {
  .demandes-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
