import { render, staticRenderFns } from "./operateurTouristique.vue?vue&type=template&id=83d9be18&scoped=true"
import script from "./operateurTouristique.vue?vue&type=script&lang=js"
export * from "./operateurTouristique.vue?vue&type=script&lang=js"
import style0 from "./operateurTouristique.vue?vue&type=style&index=0&id=83d9be18&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d9be18",
  null
  
)

export default component.exports